<template>
  <div style="height: 100%; background: #ebedf0">
    <div class="biao_ti">
      <!-- <van-icon @click="back" class="fanhui" name="arrow-left" /> -->
      <div class="tis" @click="toshow(2)">
        <div>全部团队（{{ teamlist.length }}人）</div>
      </div>
      <!-- <div class="yeji" @click="toyeji">销售业绩</div> -->
    </div>
    <div class="mian">
      <div class="hedst" v-if="active == 2">
        <!-- <div class="shijian" @click="show = true">
          <span style="vertical-align: middle">{{ times }}</span>
          <van-icon class="xiaqu" name="play" />
        </div> -->
        <div>

          <van-search v-model="keyword" show-action placeholder="请输入姓名或手机号码" @search="onSearch">
            <template #action>
              <div @click="onSearch" style="color: #585eaa;">搜索</div>
            </template>
          </van-search>

        </div>
        <van-row style="font-size: 13px">
          <van-col span="2" class="ls">#</van-col>
          <van-col span="6" class="ls">员工姓名</van-col>
          <van-col span="4" class="rs">成员数</van-col>
          <van-col span="4" class="rs">客户数</van-col>
          <van-col span="4" class="ls">月单数</van-col>
          <van-col span="4" class="rs">月收入</van-col>
        </van-row>
        <div class="neimian">
          <van-row v-for="(item, idx) in teamlist" :key="idx">
            <van-col span="2" class="ls">{{ idx + 1 }}</van-col>
            <van-col span="6" class="ls" style>
              <span style="vertical-align: middle">{{ item.name }}</span>
            </van-col>
            <van-col span="4" class="rs">{{ item.memberCounts }}</van-col>
            <van-col span="4" class="rs">{{ item.customerCounts }}</van-col>
            <van-col span="4" class="ls">{{ item.mouthCounts }}</van-col>
            <van-col span="4" class="rs" @click.native="tomy_perf(item)">
              <span style="vertical-align: middle">{{ item.mouthAmount }}</span>
              <van-icon style="vertical-align: middle; margin-top: 1px" name="arrow" />
            </van-col>
            <van-row style="border-bottom: none">
              <div class="divban">
                <span>ID</span>
                <span style="vertical-align: middle" v-if="item.uid">{{
                  item.uid
                }}</span>
                <span v-else style="color: red">未注册</span>
              </div>
              <div class="divban">
                <img class="logoimg" src="../../assets/img/dianhualogo.png" alt />
                <span style="vertical-align: middle" v-if="item.phone">{{
                  item.phone
                }}</span>
                <span v-else style="color: red">未注册</span>
              </div>
              <div class="divban">
                <img class="logoimg" src="../../assets/img/chelogo.png" alt />
                <span style="vertical-align: middle" v-if="item.carNo">{{
                  item.carNo
                }}</span>
                <span v-else style="color: red">未注册</span>
              </div>
            </van-row>
          </van-row>
          <div style="height:30px"></div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <van-popup v-model="show" position="bottom" :style="{ height: '315px' }">
      <van-datetime-picker v-model="currentDate" @confirm="toshijian" @cancel="show = false" type="year-month" title="选择年月" :min-date="minDate" :max-date="maxDate" :formatter="formatter" />
    </van-popup>
  </div>
</template>

<script>
import { sellManlisttow } from "../../api/check";
import userModel from "@/api/user";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      list: [], //
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2030, 10, 1),
      currentDate: new Date(),
      teamlist: [],
      downloadData: {
        url: "",
        icon: "",
      },
      keyword: "",
      uid: this.$route.query.uid,
      name: this.$route.query.name,
      gid: this.$route.query.gid,
      ljisok: this.$route.query.ljisok,
      show: false,
      times: "",
      mouthDate: "", //当前年月
      orderNumber: "",
      active: 2,
      index: 0,
      count: "",
      token: "",
      isok: false,
    };
  },
  methods: {
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    toshanchu(item) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否删除团队成员： " + item.name,
        })
        .then(() => {
          var data = {
            uid: item.uid,
            gid: this.shangjia.id,
            sid: this.userInfo.id,
          };
          sellMandelete(data).then((res) => {
            if (res.code == 200) {
              this.$toast("删除成功！");
              this.teamlond();
            } else {
              this.$toast(res.data.message);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    toshijian(e) {
      var _this = this;
      this.mouthDate = this.timeFormat(e, 1);
      this.times = this.timeFormat(e, 2);
      _this.teamlond();
      this.show = false;
    },
    timeFormat(time, type) {
      // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      var months = month < 10 ? "0" + month : month;
      if (type == 1) {
        return year + "-" + months;
      } else {
        return year + "年" + months + "月";
      }
    },
    toshow(idx) {
      this.active = idx;
      if (idx == 3) {
        this.malist();
      }
    },
    toyeji() {
      this.$router.push({
        name: "My_performance",
        query: {
          uid: this.uid,
          name: this.name,
          isok: "true",
          gid: this.gid,
        },
      });
    },
    tomy_perf(item) {
      this.$router.push({
        name: "My_performance",
        query: {
          uid: item.uid,
          gid: item.gid,
          name: item.name,
          boss: "true",
          isok: "true",
          idisok: "true",
          ljisok: this.ljisok,
        },
      });
    },
    onSearch() {
      this.teamlond();
    },
    teamlond() {
      sellManlisttow({
        gid: this.gid,
        mouthDate: this.mouthDate,
        keyword: this.keyword,
      }).then((res) => {
        if (res.code == 200) {
          this.teamlist = res.data;
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    addDate() {
      var nowDate = new Date();
      let date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
      };

      var month = date.month < 10 ? "0" + date.month : date.month;
      var dates = date.date < 10 ? "0" + date.date : date.date;
      // let systemDate = date.year + "-" + 0 + date.month + "-" + 0 + date.date;
      let systemDate = date.year + "-" + month;
      this.mouthDate = systemDate;
      this.times = date.year + "年" + month + "月";
      console.log(systemDate);
    },

    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "user"
      // });
    },
  },
  created() {
    // if (localStorage.getItem("Authorization") == null) {
    this.gid = this.$route.query.gid;
    if (this.$route.query.denglu == "true") {
      var url =
        this.$route.path +
        "?gid=" +
        this.$route.query.gid +
        "&ljisok=" +
        "true";
      localStorage.setItem("beforeUrl", url);
      sessionStorage.setItem("Agid", this.$route.query.gid);
      sessionStorage.setItem("gid", this.$route.query.gid);
      userModel
        .getGarageInfo({ gid: this.$route.query.gid })
        .then((e) => {
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
          // 正式
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );
          window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        })
        .catch(() => loading.clear());
      return;
    }
    this.addDate();
    this.teamlond();
  },
  mounted() {
    // this.backziding();
  },
};
</script>

<style lang="less" scoped>
.biao_ti {
  padding: 0 30px;
  height: 46px;
  background: #fff;
  width: 100%;
  font-size: 13px;
  // font-weight: bold;
  //   line-height: 46px;
  text-align: center;
  color: #333;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  overflow: hidden;
}
.yeji {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  margin-right: 15px;
  margin-top: 10px;
  color: #4169e1;
}
.mian {
  padding-top: 46px;
  height: 100%;
}
.shijian {
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
}
.tis {
  height: 46px;
  //   margin-left: 40px;
  line-height: 46px;
  font-size: 14px;
  // margin-top: 4px;
  text-align: center;
  overflow: hidden;
  position: relative;
}
.heng {
  height: 1px;
  background: #333;
  width: 100%;
  position: absolute;
  bottom: 5px;
  left: 0;
}
.fanhui {
  line-height: 46px;
  padding: 0 10px;
  position: absolute;
  left: 0;
  top: 0;
}
.heds {
  font-size: 12px;
  //   padding-top: 46px;
  margin: 12px 10px 0 10px;
  background: #fff;
  border-radius: 8px;
  min-height: 220px;
}
.hedst {
  font-size: 12px;
  width: 96%;
  margin: auto;
  //   padding-top: 46px;
  // margin: 12px 10px 0 10px;
  margin-top: 12px;
  background: #fff;
  border-radius: 8px;
  height: 95%;
  overflow: hidden;
}
.neimian {
  height: 85%;
  overflow-y: auto;
}
.van-col {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 45px;
}
.van-row {
  text-align: center;
  min-height: 45px;

  border-bottom: 1px solid #f5f5f5;
}
.neimian .van-col {
  height: 45px;
}
.xiaqu {
  transform: rotate(90deg);
  vertical-align: middle;
}
.imgse {
  background: #fff;
  height: 100%;
  overflow: hidden;
  // border-radius: 15px;
  // margin: 10px 20px;
  padding: 10px 0;
}
.ma {
  text-align: center;
  // display: block;
  margin: auto;
  margin-top: 30%;
}
.logoimg {
  width: 18px;
  vertical-align: middle;
  margin-right: 10px;
}
.divban {
  float: left;
  width: 33.3%;
  line-height: 35px;
  border-top: 1px solid #f5f5f5;
}
</style>